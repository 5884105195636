// Name:            Form
// Description:     Styles for forms
//
// Component:       `cdk-form-*`
//                  `cdk-input`
//                  `cdk-select`
//                  `cdk-textarea`
//                  `cdk-radio`
//                  `cdk-checkbox`
//                  `cdk-legend`
//                  `cdk-fieldset`
//
// Sub-objects:     `cdk-form-custom`
//                  `cdk-form-stacked`
//                  `cdk-form-horizontal`
//                  `cdk-form-label`
//                  `cdk-form-controls`
//                  `cdk-form-icon`
//                  `cdk-form-icon-flip`
//
// Modifiers:       `cdk-form-small`
//                  `cdk-form-large`
//                  `cdk-form-danger`
//                  `cdk-form-success`
//                  `cdk-form-blank`
//                  `cdk-form-width-xsmall`
//                  `cdk-form-width-small`
//                  `cdk-form-width-medium`
//                  `cdk-form-width-large`
//                  `cdk-form-controls-text`
//
// ========================================================================


// Variables
// ========================================================================

$form-height:                                    $global-control-height !default;
$form-line-height:                               $form-height !default;
$form-padding-horizontal:                        10px !default;
$form-padding-vertical:                          round($form-padding-horizontal * 0.6) !default;

$form-background:                                $global-muted-background !default;
$form-color:                                     $global-color !default;

$form-focus-background:                          darken($form-background, 5%) !default;
$form-focus-color:                               $global-color !default;

$form-disabled-background:                       $global-muted-background !default;
$form-disabled-color:                            $global-muted-color !default;

$form-placeholder-color:                         $global-muted-color !default;

$form-small-height:                              $global-control-small-height !default;
$form-small-padding-horizontal:                  8px !default;
$form-small-padding-vertical:                    round($form-small-padding-horizontal * 0.6) !default;
$form-small-line-height:                         $form-small-height !default;
$form-small-font-size:                           $global-small-font-size !default;

$form-large-height:                              $global-control-large-height !default;
$form-large-padding-horizontal:                  12px !default;
$form-large-padding-vertical:                    round($form-large-padding-horizontal * 0.6) !default;
$form-large-line-height:                         $form-large-height !default;
$form-large-font-size:                           $global-medium-font-size !default;

$form-danger-color:                              $global-danger-background !default;
$form-success-color:                             $global-success-background !default;

$form-width-xsmall:                              50px !default;
$form-width-small:                               130px !default;
$form-width-medium:                              200px !default;
$form-width-large:                               500px !default;

$form-select-padding-right:                      20px !default;
$form-select-icon-color:                         $global-color !default;
$form-select-option-color:                       $global-color !default;
$form-select-disabled-icon-color:                $global-muted-color !default;

$form-datalist-padding-right:                    20px !default;
$form-datalist-icon-color:                       $global-color !default;

$form-radio-size:                                16px !default;
$form-radio-margin-top:                          -4px !default;
$form-radio-background:                          darken($global-muted-background, 5%) !default;

$form-radio-focus-background:                    darken($form-radio-background, 5%) !default;

$form-radio-checked-background:                  $global-primary-background !default;
$form-radio-checked-icon-color:                  $global-inverse-color !default;

$form-radio-checked-focus-background:            darken($global-primary-background, 10%) !default;

$form-radio-disabled-background:                 $global-muted-background !default;
$form-radio-disabled-icon-color:                 $global-muted-color !default;

$form-legend-font-size:                          $global-large-font-size !default;
$form-legend-line-height:                        1.4 !default;

$form-stacked-margin-bottom:                     $global-small-margin !default;

$form-horizontal-label-width:                    200px !default;
$form-horizontal-label-margin-top:               7px !default;
$form-horizontal-controls-margin-left:           215px !default;
$form-horizontal-controls-text-padding-top:      7px !default;

$form-icon-width:                                $form-height !default;
$form-icon-color:                                $global-muted-color !default;
$form-icon-hover-color:                          $global-color !default;

$internal-form-select-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-datalist-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-checkbox-indeterminate-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;


/* ========================================================================
   Component: Form
 ========================================================================== */

/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */



// Hooks
// ========================================================================

@if(mixin-exists(hook-form-misc)) {@include hook-form-misc();}

// @mixin hook-form(){}
// @mixin hook-form-single-line(){}
// @mixin hook-form-multi-line(){}
// @mixin hook-form-focus(){}
// @mixin hook-form-disabled(){}
// @mixin hook-form-danger(){}
// @mixin hook-form-success(){}
// @mixin hook-form-blank(){}
// @mixin hook-form-blank-focus(){}
// @mixin hook-form-radio(){}
// @mixin hook-form-radio-focus(){}
// @mixin hook-form-radio-checked(){}
// @mixin hook-form-radio-checked-focus(){}
// @mixin hook-form-radio-disabled(){}
// @mixin hook-form-legend(){}
// @mixin hook-form-label(){}
// @mixin hook-form-stacked-label(){}
// @mixin hook-form-horizontal-label(){}
// @mixin hook-form-misc(){}


// Inverse
// ========================================================================

$inverse-form-background:                       $inverse-global-muted-background !default;
$inverse-form-color:                            $inverse-global-color !default;
$inverse-form-focus-background:                 fadein($inverse-form-background, 5%) !default;
$inverse-form-focus-color:                      $inverse-global-color !default;
$inverse-form-placeholder-color:                $inverse-global-muted-color !default;

$inverse-form-select-icon-color:                $inverse-global-color !default;

$inverse-form-datalist-icon-color:              $inverse-global-color !default;

$inverse-form-radio-background:                 $inverse-global-muted-background !default;

$inverse-form-radio-focus-background:           fadein($inverse-form-radio-background, 5%) !default;

$inverse-form-radio-checked-background:         $inverse-global-primary-background !default;
$inverse-form-radio-checked-icon-color:         $inverse-global-inverse-color !default;

$inverse-form-radio-checked-focus-background:   fadein($inverse-global-primary-background, 10%) !default;

$inverse-form-icon-color:                       $inverse-global-muted-color !default;
$inverse-form-icon-hover-color:                 $inverse-global-color !default;



// @mixin hook-inverse-form(){}
// @mixin hook-inverse-form-focus(){}
// @mixin hook-inverse-form-radio(){}
// @mixin hook-inverse-form-radio-focus(){}
// @mixin hook-inverse-form-radio-checked(){}
// @mixin hook-inverse-form-radio-checked-focus(){}
// @mixin hook-inverse-form-label(){}
