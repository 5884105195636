.cdk-button {
  background: black;
  position: relative;
}
.cdk-button-base,
.cdk-flat-button,
.cdk-pill-button {
  @apply inline-flex items-center min-h-[40px] min-w-[40px] border-0 m-0 px-0 py-0 font-semibold leading-6 text-sm rounded-md text-text-primary bg-background-paper hover:bg-disabled-light transition ease-in-out duration-150 cursor-pointer border-transparent; 
  // focus:ring-2 focus:ring-primary focus:ring-offset-2;

  &.cdk-button-default {
    @apply text-text-primary bg-background-paper hover:bg-disabled-light;
  }
  &.cdk-button-primary {
    @apply text-primary-contrast bg-primary hover:bg-primary-dark;
  }
  &.cdk-button-secondary {
    @apply text-secondary-contrast bg-secondary hover:bg-secondary-dark;
  }
  &.cdk-button-danger {
    @apply text-error-contrast bg-error hover:bg-error-dark;
  }
  &.cdk-button-success {
    @apply text-success-contrast bg-success hover:bg-success-dark;
  }
  &.cdk-button-warning {
    @apply text-warning-contrast bg-warning hover:bg-warning-dark;
  }
  &.cdk-button-info {
    @apply text-info-contrast bg-info hover:bg-info-dark;
  }
  &:disabled,
  &.cdk-button-disabled {
    @apply cursor-not-allowed text-disabled-dark bg-disabled hover:bg-disabled shadow-none;
  }
}

// .cdk-flat-button {
//   @apply px-5;
// }

.cdk-pill-button {
  @apply px-2 min-h-[34px] min-w-[40px] rounded-[34px];
}

.cdk-raised-button {
  @apply shadow;
}

.cdk-outlined-button {
  @apply text-text-secondary border-text-secondary hover:border-text-primary bg-[transparent] hover:bg-disabled-light border-2;

  &.cdk-button-default {
    @apply text-text-secondary border-text-secondary hover:border-text-primary bg-[transparent] hover:bg-disabled-light;
  }
  &.cdk-button-primary {
    @apply text-primary border-primary hover:border-primary-dark bg-[transparent] hover:bg-primary-light;
  }
  &.cdk-button-secondary {
    @apply text-secondary border-secondary hover:border-secondary-dark bg-[transparent] hover:bg-secondary-light;
  }
  &.cdk-button-danger {
    @apply text-error border-error hover:border-error-dark bg-[transparent] hover:bg-error-light;
  }
  &.cdk-button-success {
    @apply text-success border-success hover:border-success-dark bg-[transparent] hover:bg-success-light;
  }
  &.cdk-button-warning {
    @apply text-warning border-warning hover:border-warning-dark bg-[transparent] hover:bg-warning-light;
  }
  &.cdk-button-info {
    @apply text-info border-info hover:border-info-dark bg-[transparent] hover:bg-info-light;
  }
  &:disabled,
  &.cdk-button-disabled {
    @apply cursor-not-allowed text-disabled-dark bg-disabled hover:bg-disabled shadow-none px-4 py-2;
  }
}

.cdk-fab {
  @apply shadow w-[40px] h-[40px] p-0 text-center justify-center rounded-full;
}

.cdk-icon-button {
  @apply w-[40px] h-[40px] p-0 text-center justify-center text-text-secondary border-text-secondary hover:border-text-primary bg-[transparent] hover:bg-disabled-light rounded-full;

  &.cdk-button-default {
    @apply text-text-secondary bg-[transparent] hover:bg-disabled-light;
  }
  &.cdk-button-primary {
    @apply text-primary bg-[transparent] hover:bg-primary-light;
  }
  &.cdk-button-secondary {
    @apply text-secondary bg-[transparent] hover:bg-secondary-light;
  }
  &.cdk-button-danger {
    @apply text-error bg-[transparent] hover:bg-error-light;
  }
  &.cdk-button-success {
    @apply text-success bg-[transparent] hover:bg-success-light;
  }
  &.cdk-button-warning {
    @apply text-warning bg-[transparent] hover:bg-warning-light;
  }
  &.cdk-button-info {
    @apply text-info bg-[transparent] hover:bg-info-light;
  }
  &:disabled,
  &.cdk-button-disabled {
    @apply cursor-not-allowed text-disabled-dark bg-disabled hover:bg-disabled shadow-none;
  }
}

.cdk-button {
  @apply inline-flex flex-row w-full;

  .cdk-button-icon-prefix,
  .cdk-button-icon-suffix {
    @apply flex-none flex justify-center items-center w-[40px] h-[40px];
  }
  .cdk-button-text-prefix,
  .cdk-button-text-suffix {
    @apply flex-none flex justify-center items-center w-auto h-[40px];
  }

  .cdk-button-content {
    @apply flex flex-col content-center justify-center text-left px-3 flex-grow;
    &:empty {
      @apply p-0;
    }
  }
  .cdk-button-icon-prefix + .cdk-button-content,
  .cdk-button-text-prefix + .cdk-button-content {
    &:not(:empty) {
      @apply pl-1;
    }
  }
  .cdk-button-icon-suffix ~ .cdk-button-content {
    @apply pr-0;
  }
}

.cdk-badge {
  @apply absolute inline-flex items-center justify-center px-[4px] text-[10px] font-bold text-background-contrast bg-background border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900;
  &.cdk-badge-primary {
    @apply text-primary-contrast bg-primary;
  }
  &.cdk-badge-secondary {
    @apply text-secondary-contrast bg-secondary;
  }
  &.cdk-badge-danger {
    @apply text-error-contrast bg-error;
  }
  &.cdk-badge-warning {
    @apply text-warning-contrast bg-warning;
  }
  &.cdk-badge-success {
    @apply text-success-contrast bg-success;
  }
  &.cdk-badge-info {
    @apply text-info-contrast bg-info;
  }

  &.cdk-badge-top-start {
    @apply -top-2 -left-2 right-[unset] bottom-[unset];
  }
  &.cdk-badge-top-end {
    @apply -top-2 -right-2 left-[unset] bottom-[unset];
  }
  &.cdk-badge-bottom-start {
    @apply -bottom-2 -left-2 right-[unset] top-[unset];
  }
  &.cdk-badge-bottom-end {
    @apply -bottom-2 -right-2 left-[unset] top-[unset];
  }
}
