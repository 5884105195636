.cdk-item {
  @apply inline-flex flex-row gap-2;

  &.cdk-item-icon-mini {
    @apply font-semibold py-2;
  }

  .cdk-item-icon-prefix,
  .cdk-item-icon-suffix {
    @apply flex-none flex justify-center items-center w-[40px] h-[40px];
    &.cdk-item-icon-mini {
      @apply w-auto h-auto;
    }
  }
  .cdk-item-text-prefix,
  .cdk-item-text-suffix {
    @apply flex-none flex justify-center items-center w-auto h-[40px] min-w-[40px] px-2;
    &.cdk-item-icon-mini {
      @apply w-auto h-auto;
    }
  }

  .cdk-item-content {
    @apply flex flex-col content-center justify-center text-left px-3;
    &:empty {
      @apply p-0;
    }
  }
  .cdk-item-icon-prefix + .cdk-item-content,
  .cdk-item-text-prefix + .cdk-item-content {
    &:not(:empty) {
      @apply pl-1;
    }
  }
  .cdk-item-icon-suffix ~ .cdk-item-content {
    @apply pr-0;
  }
}

.cdk-list {
  @apply no-underline transition-all duration-300 list-none flex flex-col p-0 overflow-hidden text-base font-sans m-0;

  &.cdk-list-card {
    @apply rounded-[8px];
    .cdk-item {
      @apply rounded-[8px] transition-all ease-in-out text-background-contrast py-[3px] inline-flex w-full text-[16px];
    }
    &.cdk-list-base {
      // @apply bg-coolGray-100;
    }
    &.cdk-list-primary {
      @apply bg-primary-light;
    }
  }

  &.cdk-list-base {
    a.cdk-item,
    button.cdk-item {
      @apply hover:bg-primary-light hover:text-primary inline-flex w-full relative before:absolute before:w-[0px] before:h-[50%] before:block before:content-[''] before:top-[25%] before:rounded-r;
      &:before {
        transition: all 200ms;
      }
      &.active {
        @apply text-primary font-semibold bg-primary-light relative before:absolute before:w-[4px] before:h-[50%] before:bg-primary before:block before:content-[''] before:top-[25%] before:rounded-r;
      }
    }
  }

  &.cdk-list-primary {
    a.cdk-item,
    button.cdk-item  {
      @apply hover:bg-primary-light hover:text-primary inline-flex w-full;
      &.active {
        @apply text-primary;
      }
    }
  }
}
