.ek-link-arrow {
  --arrow-url: url('data:image/svg+xml;utf8,<svg width="17" height="8" viewBox="0 0 17 8" xmlns="http://www.w3.org/2000/svg"><path color="#8662FF" d="M16.3269 4.45667L16.3264 4.45729L12.9552 7.81213C12.7027 8.06345 12.2942 8.06252 12.0428 7.80994C11.7915 7.55739 11.7924 7.1489 12.045 6.89755L14.3083 4.64516H0.645162C0.288839 4.64516 0 4.35632 0 4C0 3.64367 0.288839 3.35483 0.645162 3.35483H14.3083L12.045 1.10244C11.7925 0.851088 11.7915 0.442603 12.0429 0.190054C12.2942 -0.0625591 12.7028 -0.0634298 12.9553 0.187861L16.3264 3.54271L16.327 3.54332C16.5796 3.79551 16.5788 4.20532 16.3269 4.45667Z" /></svg>');
  @apply relative text-primary no-underline pr-[20px] after:transition-all after:duration-200 after:content-['→'] after:absolute after:right-1 hover:after:right-0 hover:after:text-primary-dark;

  &.ek-link-arrow-default {
    @apply text-gray-500 hover:text-gray-dark hover:after:text-gray-dark;
  }
  &.ek-link-arrow-primary {
    @apply text-primary hover:text-primary-dark hover:after:text-primary-dark;
  }
  &.ek-link-arrow-warning {
    @apply text-warning hover:text-warning-dark hover:after:text-warning-dark;
  }
  &.ek-link-arrow-info {
    @apply text-info hover:text-info-dark hover:after:text-info-dark;
  }
  &.ek-link-arrow-error {
    @apply text-error hover:text-error-dark hover:after:text-error-dark;
  }
}
