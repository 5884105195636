// Name:            Accordion
// Description:     Component to create accordions
//
// Component:       `cdk-accordion`
//
// Sub-objects:     `cdk-accordion-title`
//                  `cdk-accordion-content`
//
// States:          `cdk-open`
//
// ========================================================================


// Variables
// ========================================================================

$accordion-item-margin-top:                     $global-margin !default;

$accordion-title-font-size:                     $global-medium-font-size !default;
$accordion-title-line-height:                   1.4 !default;
$accordion-title-color:                         $global-emphasis-color !default;
$accordion-title-hover-color:                   $global-color !default;

$accordion-content-margin-top:                  $global-margin !default;


/* ========================================================================
   Component: Accordion
 ========================================================================== */

.cdk-accordion {
    padding: 0;
    list-style: none;
    @if(mixin-exists(hook-accordion)) {@include hook-accordion();}
}


/* Item
 ========================================================================== */

.cdk-accordion > :nth-child(n+2) {
    margin-top: $accordion-item-margin-top;
    @if(mixin-exists(hook-accordion-item)) {@include hook-accordion-item();}
}


/* Title
 ========================================================================== */

.cdk-accordion-title {
    display: block;
    font-size: $accordion-title-font-size;
    line-height: $accordion-title-line-height;
    color: $accordion-title-color;
    @if(mixin-exists(hook-accordion-title)) {@include hook-accordion-title();}
}

/* Hover */
.cdk-accordion-title:hover {
    color: $accordion-title-hover-color;
    text-decoration: none;
    @if(mixin-exists(hook-accordion-title-hover)) {@include hook-accordion-title-hover();}
}


/* Content
 ========================================================================== */

.cdk-accordion-content {
    display: flow-root;
    margin-top: $accordion-content-margin-top;
    @if(mixin-exists(hook-accordion-content)) {@include hook-accordion-content();}
}

/*
 * Remove margin from the last-child
 */

 .cdk-accordion-content > :last-child { margin-bottom: 0; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-accordion-misc)) {@include hook-accordion-misc();}

// @mixin hook-accordion(){}
// @mixin hook-accordion-item(){}
// @mixin hook-accordion-title(){}
// @mixin hook-accordion-title-hover(){}
// @mixin hook-accordion-content(){}
// @mixin hook-accordion-misc(){}


// Inverse
// ========================================================================

$inverse-accordion-title-color:              $inverse-global-emphasis-color !default;
$inverse-accordion-title-hover-color:        $inverse-global-color !default;



// @mixin hook-inverse-accordion-item(){}
// @mixin hook-inverse-accordion-title(){}
// @mixin hook-inverse-accordion-title-hover(){}
