// Name:            Inverse
// Description:     Inverse component style for light or dark backgrounds
//
// Component:       `cdk-light`
//                  `cdk-dark`
//
// ========================================================================


// Variables
// ========================================================================

$inverse-global-color-mode:                      light !default;

$inverse-global-color:                           rgba($global-inverse-color, 0.7) !default;
$inverse-global-emphasis-color:                  $global-inverse-color !default;
$inverse-global-muted-color:                     rgba($global-inverse-color, 0.5) !default;
$inverse-global-inverse-color:                   $global-color !default;

$inverse-global-primary-background:              $global-inverse-color !default;
$inverse-global-muted-background:                rgba($global-inverse-color, 0.1) !default;

$inverse-global-border:                          rgba($global-inverse-color, 0.2) !default;


/* ========================================================================
   Component: Inverse
 ========================================================================== */



/*
 * Implemented class depends on the general theme color
 * `cdk-light` is for light colors on dark backgrounds
 * `cdk-dark` is or dark colors on light backgrounds
 */

@if ($inverse-global-color-mode == light) { .cdk-light { @if (mixin-exists(hook-inverse)) {@include hook-inverse();}}}

@if ($inverse-global-color-mode == dark) { .cdk-dark { @if (mixin-exists(hook-inverse)) {@include hook-inverse();}}}


// Hooks
// ========================================================================

// @mixin hook-inverse(){}
