// Name:            Background
// Description:     Utilities for backgrounds
//
// Component:       `cdk-background-*`
//
// ========================================================================


// Variables
// ========================================================================

$background-default-background:                  $global-background !default;
$background-muted-background:                    $global-muted-background !default;
$background-primary-background:                  $global-primary-background !default;
$background-secondary-background:                $global-secondary-background !default;


/* ========================================================================
   Component: Background
 ========================================================================== */


/* Color
 ========================================================================== */

.cdk-background-default { background-color: $background-default-background; }
.cdk-background-muted { background-color: $background-muted-background; }
.cdk-background-primary { background-color: $background-primary-background; }
.cdk-background-secondary { background-color: $background-secondary-background; }


/* Size
 ========================================================================== */

.cdk-background-cover,
.cdk-background-contain,
.cdk-background-width-1-1,
.cdk-background-height-1-1 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.cdk-background-cover { background-size: cover; }
.cdk-background-contain { background-size: contain; }
.cdk-background-width-1-1 { background-size: 100%; }
.cdk-background-height-1-1 { background-size: auto 100%; }


/* Position
 ========================================================================== */

.cdk-background-top-left { background-position: 0 0; }
.cdk-background-top-center { background-position: 50% 0; }
.cdk-background-top-right { background-position: 100% 0; }
.cdk-background-center-left { background-position: 0 50%; }
.cdk-background-center-center { background-position: 50% 50%; }
.cdk-background-center-right { background-position: 100% 50%; }
.cdk-background-bottom-left { background-position: 0 100%; }
.cdk-background-bottom-center { background-position: 50% 100%; }
.cdk-background-bottom-right { background-position: 100% 100%; }


/* Repeat
 ========================================================================== */

.cdk-background-norepeat { background-repeat: no-repeat; }


/* Attachment
 ========================================================================== */

/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */

.cdk-background-fixed {
    background-attachment: fixed;
    /* 1 */
    backface-visibility: hidden;
}

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */

@media (pointer: coarse) {
    .cdk-background-fixed { background-attachment: scroll; }
}


/* Image
 ========================================================================== */

/* Phone portrait and smaller */
@media (max-width: $breakpoint-xsmall-max) {

    .cdk-background-image\@s { background-image: none !important; }

}

/* Phone landscape and smaller */
@media (max-width: $breakpoint-small-max) {

    .cdk-background-image\@m { background-image: none !important; }

}

/* Tablet landscape and smaller */
@media (max-width: $breakpoint-medium-max) {

    .cdk-background-image\@l { background-image: none !important; }

}

/* Desktop and smaller */
@media (max-width: $breakpoint-large-max) {

    .cdk-background-image\@xl {background-image: none !important; }

}


/* Blend modes
 ========================================================================== */

.cdk-background-blend-multiply { background-blend-mode: multiply; }
.cdk-background-blend-screen { background-blend-mode: screen; }
.cdk-background-blend-overlay { background-blend-mode: overlay; }
.cdk-background-blend-darken { background-blend-mode: darken; }
.cdk-background-blend-lighten { background-blend-mode: lighten; }
.cdk-background-blend-color-dodge { background-blend-mode: color-dodge; }
.cdk-background-blend-color-burn { background-blend-mode: color-burn; }
.cdk-background-blend-hard-light { background-blend-mode: hard-light; }
.cdk-background-blend-soft-light { background-blend-mode: soft-light; }
.cdk-background-blend-difference { background-blend-mode: difference; }
.cdk-background-blend-exclusion { background-blend-mode: exclusion; }
.cdk-background-blend-hue { background-blend-mode: hue; }
.cdk-background-blend-saturation { background-blend-mode: saturation; }
.cdk-background-blend-color { background-blend-mode: color; }
.cdk-background-blend-luminosity { background-blend-mode: luminosity; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-background-misc)) {@include hook-background-misc();}

// @mixin hook-background-misc(){}
