.ek-card {
  @apply rounded-lg border border-gray-200 relative overflow-hidden w-full;

  &.ek-card-primary {
    @apply bg-primary text-primary-contrast shadow-md;
  }

  &.ek-card-secondary {
    @apply bg-secondary text-secondary-contrast shadow-md;
  }

  &.ek-card-error {
    @apply bg-error text-error-contrast shadow-md;
  }

  &.ek-card-info {
    @apply bg-info text-info-contrast shadow-md;
  }

  &.ek-card-success {
    @apply bg-success text-success-contrast shadow-md;
  }

  &.ek-card-warning {
    @apply bg-warning text-warning-contrast shadow-md;
  }
}

.ek-card-body {
  @apply p-4;
}

