// Name:            Switcher
// Description:     Component to navigate through different content panes
//
// Component:       `cdk-switcher`
//
// States:          `cdk-active`
//
// ========================================================================


/* ========================================================================
   Component: Switcher
 ========================================================================== */

/*
 * Reset list
 */

.cdk-switcher {
    margin: 0;
    padding: 0;
    list-style: none;
}


/* Items
 ========================================================================== */

/*
 * Hide not active items
 */

.cdk-switcher > :not(.cdk-active) { display: none; }

/*
 * Remove margin from the last-child
 */

.cdk-switcher > * > :last-child { margin-bottom: 0; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-switcher-misc)) {@include hook-switcher-misc();}

// @mixin hook-switcher-misc(){}
