// Name:            Drop
// Description:     Component to position any element next to any other element.
//
// Component:       `cdk-drop`
//
// Modifiers:       `cdk-drop-top-*`
//                  `cdk-drop-bottom-*`
//                  `cdk-drop-left-*`
//                  `cdk-drop-right-*`
//                  `cdk-drop-stack`
//                  `cdk-drop-grid`
//
// States:          `cdk-open`
//
// Uses:            Animation
//
// ========================================================================


// Variables
// ========================================================================

$drop-z-index:                               $global-z-index + 20 !default;
$drop-width:                                 300px !default;
$drop-margin:                                $global-margin !default;


/* ========================================================================
   Component: Drop
 ========================================================================== */

/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */

.cdk-drop {
    /* 1 */
    display: none;
    /* 2 */
    position: absolute;
    z-index: $drop-z-index;
    /* 3 */
    box-sizing: border-box;
    width: $drop-width;
}

/* Show */
.cdk-drop.cdk-open { display: block; }


/* Direction / Alignment modifiers
 ========================================================================== */

/* Direction */
[class*='cdk-drop-top'] { margin-top: (-$drop-margin); }
[class*='cdk-drop-bottom'] { margin-top: $drop-margin; }
[class*='cdk-drop-left'] { margin-left: (-$drop-margin); }
[class*='cdk-drop-right'] { margin-left: $drop-margin; }


/* Grid modifiers
 ========================================================================== */

.cdk-drop-stack .cdk-drop-grid > * { width: 100% !important; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-drop-misc)) {@include hook-drop-misc();}

// @mixin hook-drop-misc(){}
