.tu-navbar {
  @apply w-full bg-background-paper flex flex-col font-sans z-[900] relative;

  .cdk-button-icon-circle > .cdk-item-icon-prefix {
    @apply h-[37px] w-[37px] flex items-center;
    & > span {
      @apply h-[28px] w-[28px] flex items-center rounded-full bg-primary;
    }
  }
}

.tu-navbar-top,
.tu-navbar-bottom {
  @apply w-full;

  .tu-navbar-container {
    @apply flex items-stretch relative px-[36px] max-w-[1690px] m-auto;
  }

  .tu-logo {
    @apply max-h-[24px] self-center;
  }

  ul.tu-nav-list {
    @apply p-[4px] flex items-stretch list-none m-0;

    &.tu-nav-start {
      @apply justify-start;
    }

    &.tu-nav-center {
      @apply justify-center;
    }

    &.tu-nav-end {
      @apply justify-end;
    }
  }
  .tu-nav-item,
  ul.tu-nav-list > li {
    @apply self-center px-[4px];
  }
  .tu-nav-item,
  ul.tu-nav-list > li > span.block > a,
  ul.tu-nav-list > li > a {
    @apply flex p-[4px] pr-[8px] pl-[8px] bg-transparent;
  }

  ul.tu-nav-list > li > span.block > a,
  ul.tu-nav-list > li > a {
    @apply transition-all ease-in-out font-semibold min-h-[36px] leading-[36px] rounded-[6px] no-underline text-gray-dark hover:bg-primary-light hover:text-primary;
    &.cdk-item {
      @apply opacity-80 hover:opacity-90;
      .cdk-item-icon-prefix {
        @apply w-[40px] h-[40px];
      }
      .cdk-item-content {
        @apply px-0;
      }
      .cdk-item-icon-prefix + .cdk-item-content:not(:empty),
      .cdk-item-text-prefix + .cdk-item-content:not(:empty) {
        @apply pl-2;
      }
    }
  }

  ul.tu-nav-list > li.tu-active > span.block > a,
  ul.tu-nav-list > li.tu-active > a {
    @apply font-bold text-primary opacity-100;
  }

  ul.tu-nav-list > li > button {
    // @apply mx-[4px];
  }

  ul.tu-nav-list > li.tu-active > button {
    @apply bg-primary opacity-100 text-primary-DEFAULT-on;
  }

  ul.tu-nav-list > li > .tu-icon-button {
    @apply flex self-center justify-center p-0 m-[4px] h-[38px] w-[38px] leading-[38px] rounded-full no-underline bg-[transparent] border-none;
  }

  ul.tu-nav-list > li > .tu-nav-user {
    @apply flex self-center justify-center p-0 m-[4px] h-[38px] w-[38px] leading-[38px] rounded-full no-underline bg-primary text-[white] hover:bg-primary-dark hover:text-[white];
  }
}

.tu-navbar-top {
  @apply h-[56px] bg-navbar-top;
}
.tu-navbar-bottom {
  @apply h-auto bg-navbar-bottom border-0 border-solid border-b border-t border-navbar-border;
}

