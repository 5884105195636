// Name:            Slider
// Description:     Component to create horizontal sliders
//
// Component:       `cdk-slider`
//
// Sub-objects:     `cdk-slider-container`
//                  `cdk-slider-items`
//
// States:          `cdk-active`
//
// ========================================================================


// Variables
// ========================================================================

$slider-container-margin-top:                        -11px !default;
$slider-container-margin-bottom:                     -39px !default;
$slider-container-margin-left:                       -25px !default;
$slider-container-margin-right:                      -25px !default;


/* ========================================================================
   Component: Slider
 ========================================================================== */

/*
 * 1. Prevent tab highlighting on iOS.
 */

.cdk-slider {
    /* 1 */
    -webkit-tap-highlight-color: transparent;
    @if(mixin-exists(hook-slider)) {@include hook-slider();}
}


/* Container
 ========================================================================== */

/*
 * Clip child elements
 */

.cdk-slider-container { overflow: hidden; }

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */

.cdk-slider-container-offset {
    margin: $slider-container-margin-top $slider-container-margin-right $slider-container-margin-bottom $slider-container-margin-left;
    padding: ($slider-container-margin-top * -1) ($slider-container-margin-right * -1) ($slider-container-margin-bottom * -1) ($slider-container-margin-left * -1);
}

/* Items
 ========================================================================== */

/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 */

.cdk-slider-items {
    /* 1 */
    will-change: transform;
    /* 2 */
    position: relative;
}

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */

.cdk-slider-items:not(.cdk-grid) {
    display: flex;
    /* 1 */
    margin: 0;
    padding: 0;
    list-style: none;
    /* 2 */
    -webkit-touch-callout: none;
}

.cdk-slider-items.cdk-grid { flex-wrap: nowrap; }


/* Item
 ========================================================================== */

/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 * 4. Disable horizontal panning gestures in IE11 and Edge
 */

.cdk-slider-items > * {
    /* 1 */
    flex: none;
    max-width: 100%;
    /* 3 */
    position: relative;
    /* 4 */
    touch-action: pan-y;
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-slider-misc)) {@include hook-slider-misc();}

// @mixin hook-slider(){}
// @mixin hook-slider-misc(){}
