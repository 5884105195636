// Name:            Height
// Description:     Utilities for heights
//
// Component:       `cdk-height-*`
//
// ========================================================================


// Variables
// ========================================================================

$height-small-height:                            150px !default;
$height-medium-height:                           300px !default;
$height-large-height:                            450px !default;


/* ========================================================================
   Component: Height
 ========================================================================== */

[class*='cdk-height'] { box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */

.cdk-height-1-1 { height: 100%; }

/*
 * Useful to create image teasers
 */

.cdk-height-viewport { min-height: 100vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */

.cdk-height-small { height: $height-small-height; }
.cdk-height-medium { height: $height-medium-height; }
.cdk-height-large { height: $height-large-height; }

.cdk-height-max-small { max-height: $height-small-height; }
.cdk-height-max-medium { max-height: $height-medium-height; }
.cdk-height-max-large { max-height: $height-large-height; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-height-misc)) {@include hook-height-misc();}

// @mixin hook-height-misc(){}
