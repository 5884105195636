// Name:            Label
// Description:     Component to indicate important notes
//
// Component:       `cdk-label`
//
// Modifiers:       `cdk-label-success`
//                  `cdk-label-warning`
//                  `cdk-label-danger`
//
// ========================================================================


// Variables
// ========================================================================

$label-padding-vertical:                         0 !default;
$label-padding-horizontal:                       $global-small-margin !default;
$label-background:                               $global-primary-background !default;
$label-line-height:                              $global-line-height !default;
$label-font-size:                                $global-small-font-size !default;
$label-color:                                    $global-inverse-color !default;

$label-success-background:                       $global-success-background !default;
$label-success-color:                            $global-inverse-color !default;
$label-warning-background:                       $global-warning-background !default;
$label-warning-color:                            $global-inverse-color !default;
$label-danger-background:                        $global-danger-background !default;
$label-danger-color:                             $global-inverse-color !default;


/* ========================================================================
   Component: Label
 ========================================================================== */

.cdk-badge {
    display: inline-block;
    padding: $label-padding-vertical $label-padding-horizontal;
    background: $label-background;
    line-height: $label-line-height;
    font-size: $label-font-size;
    color: $label-color;
    vertical-align: middle;
    white-space: nowrap;
    @if(mixin-exists(hook-label)) {@include hook-label();}
}


/* Color modifiers
 ========================================================================== */

/*
 * Success
 */

.cdk-badge-success {
    background-color: $label-success-background;
    color: $label-success-color;
    @if(mixin-exists(hook-label-success)) {@include hook-label-success();}
}

/*
 * Warning
 */

.cdk-badge-warning {
    background-color: $label-warning-background;
    color: $label-warning-color;
    @if(mixin-exists(hook-label-warning)) {@include hook-label-warning();}
}

/*
 * Danger
 */

.cdk-badge-danger {
    background-color: $label-danger-background;
    color: $label-danger-color;
    @if(mixin-exists(hook-label-danger)) {@include hook-label-danger();}
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-label-misc)) {@include hook-label-misc();}

// @mixin hook-label(){}
// @mixin hook-label-success(){}
// @mixin hook-label-warning(){}
// @mixin hook-label-danger(){}
// @mixin hook-label-misc(){}


// Inverse
// ========================================================================

$inverse-label-background:                      $inverse-global-primary-background !default;
$inverse-label-color:                           $inverse-global-inverse-color !default;



// @mixin hook-inverse-label(){}
