@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system',
    'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial',
    'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}
// 1. Your custom variables and variable overwrites.
$global-link-color: #da7d02;

// 2. Import default variables and available mixins.
@import 'cdkit/variables-theme.scss';
@import 'cdkit/mixins-theme.scss';

// 3. Your custom mixin overwrites.
// @mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import 'cdkit/cdk-theme.scss';

* {
  @apply text-base font-sans box-border;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  scroll-behavior: smooth;
  @apply text-base font-sans leading-6;
}
body {
  @apply text-base font-sans leading-6 m-0;
}

a {
  @apply no-underline decoration-transparent text-primary hover:text-primary-dark;
}

@import 'components';

.cdk-page-container {
  @apply max-w-[1690px] m-auto w-full px-[40px];
}

.cdk-card-default {
  @apply shadow-none border border-disabled-light border-solid rounded-[8px];
}

[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
  @apply bg-primary;
}

.ek-page-header {
  @apply py-7;

  .ek-page-title {
    @apply text-background-contrast text-[20px] font-semibold capitalize mb-1;
  }
  .ek-page-sub-title {
    @apply text-background-contrast/50 text-[14px] capitalize m-0;
  }
}
.ek-jumbo-container {
  @apply bg-navbar-top p-[40px] relative rounded-lg;
}
