.cdk-menu {
  .cdk-menu-trigger {
  }
  .cdk-menu-dropdown {
    @apply z-50;
    .ngxp__arrow {
      @apply shadow-lg;
    }
    .ngxp__container {
      @apply p-0 border border-outline shadow-lg rounded-[8px] bg-background-paper;
      /* & > div > ul {
          @apply px-0 min-w-[12rem] text-base float-left py-2 list-none text-left;
          & > li {
            @apply block w-full text-sm font-normal bg-transparent whitespace-nowrap text-text-primary;
            & > a {
              @apply no-underline text-text-primary px-4 py-2 block;
            }
          }
        } */
    }
  }

  .ngxp__container > .ngxp__arrow {
    // margin-left: 8px;
    // margin-right: 8px;
    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      z-index: -1;
      border: 1px solid #0000001f;
    }
    &:after {
      content: '';
      background-color: white;
      position: absolute;
      width: 14px;
      height: 10px;
      top: 4px;
      left: -2px;
    }
  }
}

ul.cdk-list-menu,
.cdk-list-menu {
  @apply min-w-[12rem] text-base m-0 py-2 px-2 list-none text-left;
  & > li,
  & > * {
    @apply w-full text-sm font-normal bg-transparent whitespace-nowrap text-text-primary;
    & > a {
      @apply flex no-underline transition-all duration-300 opacity-70 text-text-primary hover:opacity-100 hover:font-semibold hover:bg-primary-light hover:text-primary rounded-[8px] px-0 py-0;
      .cdk-item-content {
        @apply leading-10;
      }
    }
    &.tu-active > a {
      @apply opacity-100 font-semibold bg-primary-light text-primary;
    }
    &.cdk-divider {
      @apply border-0 border-b-[1px] border-solid border-gray-200;
    }
    &.cdk-item-primary {
      @apply hover:bg-primary-light hover:text-primary;
    }
    &.cdk-item-secondary {
      @apply hover:bg-secondary-light bg-opacity-10 hover:text-secondary;
    }
    &.cdk-item-danger {
      @apply hover:bg-error-light bg-opacity-10 hover:text-error;
    }
    &.cdk-item-success {
      @apply hover:bg-success-light bg-opacity-10 hover:text-success;
    }
    &.cdk-item-warning {
      @apply hover:bg-warning-light bg-opacity-10 hover:text-warning;
    }
    &.cdk-item-info {
      @apply hover:bg-info-light bg-opacity-10 hover:text-info;
    }
  }
}

