// Name:            Cover
// Description:     Utilities to let embedded content cover their container in a centered position
//
// Component:       `cdk-cover`
//
// Sub-object:      `cdk-cover-container`
//
// ========================================================================


/* ========================================================================
   Component: Cover
 ========================================================================== */

/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */

/* 1 */
[cdk-cover],
[data-cdk-cover] {
    /* 2 */
    max-width: none;
    /* 3 */
    position: absolute;
    left: 50%;
    top: 50%;
    --cdk-position-translate-x: -50%;
    --cdk-position-translate-y: -50%;
    transform: translate(var(--cdk-position-translate-x), var(--cdk-position-translate-y));
}

iframe[cdk-cover],
iframe[data-cdk-cover] { pointer-events: none; }


/* Container
 ========================================================================== */

/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */

.cdk-cover-container {
    /* 1 */
    overflow: hidden;
    /* 2 */
    position: relative;
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-cover-misc)) {@include hook-cover-misc();}

// @mixin hook-cover-misc(){}
